import { Component, Vue } from "vue-property-decorator";
import {
  ReviewerPeersInterface,
  filters,
} from "@/models/reviewer-peer-applications/reviewer-peers-applications.interface";
import moment from "moment";
import {
  dateRange,
  reviewerDataTable,
} from "@/models/common-interface/common.interface";

import DateRangePicker from "vue2-daterange-picker";
import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { utils } from "@/utils/utils";

import {
  SortingKeyName,
  SortingOrderKey,
  TableSortEvent,
  FilterStateStorageNames,
} from "@/models/constants/filters.interface";
import { PEER_FLAG_APPLICANT_QUERY } from "@/graphql/peer-flag-applicant";
import FlagApplicantPopupComponent from "../flag-applicant/flag-applicant.component.vue";
import { gql } from "graphql-tag";
@Component({
  name: "reviewer-peers-page",
  components: {
    DateRangePicker,
    AppLoaderComponent,
    FlagApplicantPopupComponent,
  },
})
export default class ReviewerPeersPage extends Vue {
  public peersData: ReviewerPeersInterface[] = [];
  public limit = 10;
  public currentPage = 1;
  public totalItem = 0;
  public page = 1;
  public filterDetails: filters = {} as filters;
  public showFilters = false;
  public dateRange: dateRange = {} as dateRange;
  public certifiedDateRange: dateRange = {} as dateRange;
  public startDate: any;
  public endDate: any;
  public expiryFromDate: any;
  public expiryToDate: any;
  public offset = 0;
  public dateFilter = "";
  public firstName: any;
  public lastName: any;
  public email: any;
  public isLoading = false;
  public errorMessage = "";
  public isDataEmpty = false;
  public sort = "first_name,last_name";
  public sortBy = SortingOrderKey.ASC;
  public certifiedDateFilter = "";
  public tableFields = [
    { key: "firstName", label: "First Name", sortable: true },
    { key: "lastName", label: "Last Name", sortable: true },
    { key: "email", label: "Email Address", sortable: true },
    {
      key: "certificateNumber",
      label: "Certificate Number",
      thClass: "certifiactions",
    },
    {
      key: "certificateDate",
      label: "Certified Date",
      thClass: "certifiactions",
    },
    {
      key: "expiryDate",
      label: "Expiration Date",
      thClass: "renewal_date",
    },
    {
      key: "action",
      label: "Action",
      thClass: "application_action pointer-events",
    },
  ];
  public flagSelected: any = [];
  public flagApplicantNameGet: any = [];
  public flagFiterChip = false;
  public flagPageData: any = {};
  public flagApplicantGrapql: any = {};
  public flagApplicantPopUp = false;
  public created() {
    const peerFilter = localStorage.getItem(FilterStateStorageNames.PEERS)
      ? JSON.parse(localStorage.getItem(FilterStateStorageNames.PEERS) || "")
      : null;
    if (peerFilter) {
      this.filterDetails = { ...this.filterDetails, ...peerFilter };
      localStorage.removeItem(FilterStateStorageNames.PEERS);
      this.expiryFromDate = peerFilter.expiryFromDate;
      this.expiryToDate = peerFilter.expiryToDate;
      this.startDate = peerFilter.startDate;
      this.endDate = peerFilter.endDate;
      this.limit = peerFilter.limit || this.limit;
      this.offset = peerFilter.offset || this.offset;
      this.page = (this.offset + this.limit) / 10;
      this.dateFilter =
        peerFilter && peerFilter.expiryFromDate && peerFilter.expiryToDate
          ? peerFilter.expiryFromDate + " - " + peerFilter.expiryToDate
          : "";
      this.certifiedDateFilter =
        peerFilter &&
        peerFilter.certificateFromDate &&
        peerFilter.certificateToDate
          ? peerFilter.certificateFromDate +
            " - " +
            peerFilter.certificateToDate
          : "";
    }
    this.getPeerApplicationLists();
    this.flagApplicantGrapqlQuery();
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.getPeerApplicationLists();
  }

  public navigation(peer: any) {
    localStorage.setItem(
      FilterStateStorageNames.PEERS,
      JSON.stringify(this.filterDetails)
    );
    this.$router.push({
      path: `peers-preview`,
      query: {
        id: peer.item.id,
      },
    });
  }

  public searchOnEnter() {
    this.getPeerApplicationLists();
  }

  public clearSearchText() {
    this.flagSelected = [];
    this.filterDetails.firstName = "";
    this.filterDetails.lastName = "";
    this.filterDetails.email = "";
    this.startDate = "";
    this.endDate = "";
    this.expiryFromDate = "";
    this.expiryToDate = "";
    this.dateFilter = "";
    this.certifiedDateFilter = "";
    this.filterDetails.certificateNumber = "";
    this.sort = "first_name,last_name";
    this.sortBy = SortingOrderKey.ASC;
    this.getPeerApplicationLists();
  }

  public getPeerApplicationLists() {
    this.filterDetails.flags =
      this.flagApplicantNameGet.length == 0
        ? (this.flagSelected = [])
        : this.flagSelected;
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.expiryFromDate = this.expiryFromDate;
    this.filterDetails.expiryToDate = this.expiryToDate;
    this.filterDetails.certificateFromDate = this.startDate;
    this.filterDetails.certificateToDate = this.endDate;
    this.filterDetails.sortBy = this.sort;
    this.filterDetails.sort = this.sortBy;
    this.isLoading = true;
    const email = this.filterDetails.email
      ? encodeURIComponent(this.filterDetails.email)
      : null;
    this.peersData = [];
    getApplicationDetails
      .getPeersApplicationList({ ...this.filterDetails, email })
      .then((data) => {
        this.peersData = data.peers;
        this.isLoading = false;
        this.totalItem = data.totalCount;
        if (data.peers.length > 0) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
        this.peersData.forEach((element: ReviewerPeersInterface) => {
          element.certificateDate = element.certificateDate
            ? moment(element.certificateDate).format("MM-DD-YYYY")
            : "--";
          element.expiryDate = element.expiryDate
            ? moment(element.expiryDate).format("MM-DD-YYYY")
            : "--";
          element.certificateNumber = element.certificateNumber
            ? element.certificateNumber
            : "--";
        });
      })
      .catch((error) => {
        this.isDataEmpty = true;
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public formTypeTableRowClass(item: reviewerDataTable, type: string) {
    return utils.formTypeTableRowClass(item, type);
  }

  public sortChanged(event: TableSortEvent) {
    if (event) {
      this.offset = 0;
      this.currentPage = 1;
    }
    if (
      event.sortBy &&
      SortingKeyName[event.sortBy as keyof typeof SortingKeyName] !== undefined
    ) {
      this.sort = SortingKeyName[event.sortBy as keyof typeof SortingKeyName];
      this.sortBy = event?.sortDesc
        ? SortingOrderKey.DESC
        : SortingOrderKey.ASC;
    }
    this.getPeerApplicationLists();
  }
  public dateChip(type: any) {
    if (this.dateRange) {
      if (type === "expiry") {
        this.expiryFromDate = moment(new Date(this.dateRange.startDate)).format(
          "MM-DD-YYYY"
        );
        this.expiryToDate = moment(new Date(this.dateRange.endDate)).format(
          "MM-DD-YYYY"
        );
        this.dateFilter = this.expiryFromDate + " - " + this.expiryToDate;
      }
    }
    if (this.certifiedDateRange) {
      if (type === "cerified") {
        this.startDate = moment(
          new Date(this.certifiedDateRange.startDate)
        ).format("MM-DD-YYYY");
        this.endDate = moment(new Date(this.certifiedDateRange.endDate)).format(
          "MM-DD-YYYY"
        );
        this.certifiedDateFilter = this.startDate + " - " + this.endDate;
      }
    }
    this.offset = 0;
    this.currentPage = 1;
  }
  public onClearStatusFilter(isDateFilter = false, index: any) {
    if (isDateFilter) {
      this.dateFilter = "";
      this.expiryFromDate = "";
      this.expiryToDate = "";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
    } else {
      this.certifiedDateFilter = "";
      this.startDate = "";
      this.endDate = "";
      this.certifiedDateRange.startDate = null;
      this.certifiedDateRange.endDate = null;
    }
    this.offset = 0;
    this.currentPage = 1;
    this.getPeerApplicationLists();
  }
  public flagApplicantGrapqlQuery() {
    this.$apollo
      .query({
        query: gql`query ${PEER_FLAG_APPLICANT_QUERY}`,
      })
      .then((result) => {
        this.flagApplicantGrapql = result;
      });
  }
  public flagOnClearChip(flagIndexGet: any) {
    const chipClearFilter = this.flagSelected.filter(function (value: any) {
      return value !== flagIndexGet.item;
    });
    this.flagSelected = chipClearFilter;
    this.flagDropDownApply();
  }
  public flagDropDownApply() {
    (this.$refs.dropdown as any).hide();
    this.flagApplicantNameGet =
      this.flagApplicantGrapql.data.peerFlagApplicant.data.attributes.form_fields.formInputs[0][0].propBind.options.filter(
        (person: any) => this.flagSelected.includes(person.item)
      );
    this.getPeerApplicationLists();
  }
  public flagDropDownCheckBox() {
    if (this.flagSelected.length >= 1) {
      this.flagFiterChip = true;
    } else {
      this.flagFiterChip = false;
    }
  }
  public onClearFlagFilter() {
    this.flagSelected = [];
  }

  getPeerReportsAndApplicationList() {
    this.getPeerApplicationLists();
  }

  public flagApplicantPopup() {
    this.getPeerReportsAndApplicationList();
  }
  public flagApplicant(row: any) {
    this.$bvModal.show("flag-applicant-modal");
    this.flagPageData = row.item;
  }
}
