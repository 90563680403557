import { http } from "../http";
import { utils } from "@/utils/utils";

export class GetApplicationDetails {
  private static INSTANCE = new GetApplicationDetails();

  static get instance() {
    return this.INSTANCE;
  }

  public getTrainingApplicationList(filters: any) {
    const filterKeys: string[] = [];
    if (filters["limit"]) {
      const filterKey = `limit=${filters["limit"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["offset"] >= 0) {
      const filterKey = `offset=${filters["offset"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["status"]) {
      const filterKey = `status=${filters["status"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["from"]) {
      const filterKey = `from=${filters["from"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["to"]) {
      const filterKey = `to=${filters["to"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sort"]) {
      const filterKey = `sort=${filters["sort"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sortBy"]) {
      const filterKey = `sortBy=${filters["sortBy"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["firstName"]) {
      const filterKey = `firstName=${filters["firstName"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["lastName"]) {
      const filterKey = `lastName=${filters["lastName"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["email"]) {
      const filterKey = `email=${encodeURIComponent(filters["email"])}`;
      filterKeys.push(filterKey);
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cyapss/training/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }

  public getCertificationApplicationList(filters: any) {
    const filterKeys: string[] = [];
    if (filters["limit"]) {
      const filterKey = `limit=${filters["limit"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["offset"] >= 0) {
      const filterKey = `offset=${filters["offset"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["status"]) {
      const filterKey = `status=${filters["status"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["from"]) {
      const filterKey = `from=${filters["from"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["to"]) {
      const filterKey = `to=${filters["to"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sort"]) {
      const filterKey = `sort=${filters["sort"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sortBy"]) {
      const filterKey = `sortBy=${filters["sortBy"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["firstName"]) {
      const filterKey = `firstName=${filters["firstName"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["lastName"]) {
      const filterKey = `lastName=${filters["lastName"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["email"]) {
      const filterKey = `email=${encodeURIComponent(filters["email"])}`;
      filterKeys.push(filterKey);
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cyapss/certificate/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }

  public getRenewalApplicationList(filters: any) {
    const filterKeys: string[] = [];
    if (filters["limit"]) {
      const filterKey = `limit=${filters["limit"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["offset"] >= 0) {
      const filterKey = `offset=${filters["offset"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["status"]) {
      const filterKey = `status=${filters["status"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["from"]) {
      const filterKey = `from=${filters["from"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["to"]) {
      const filterKey = `to=${filters["to"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sort"]) {
      const filterKey = `sort=${filters["sort"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sortBy"]) {
      const filterKey = `sortBy=${filters["sortBy"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["firstName"]) {
      const filterKey = `firstName=${filters["firstName"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["lastName"]) {
      const filterKey = `lastName=${filters["lastName"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["email"]) {
      const filterKey = `email=${encodeURIComponent(filters["email"])}`;
      filterKeys.push(filterKey);
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cyapss/certificate/renewal/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }

  public getSupervisorList(filters: any) {
    const filterKeys: string[] = [];
    if (filters["limit"]) {
      const filterKey = `limit=${filters["limit"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["offset"] >= 0) {
      const filterKey = `offset=${filters["offset"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sort"]) {
      const filterKey = `sort=${filters["sort"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sortBy"]) {
      const filterKey = `sortBy=${filters["sortBy"]}`;
      filterKeys.push(filterKey);
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cyapss/supervisor/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }

  public getAgencyList(filters: any) {
    const filterKeys: string[] = [];
    if (filters["limit"]) {
      const filterKey = `limit=${filters["limit"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["offset"] >= 0) {
      const filterKey = `offset=${filters["offset"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sort"]) {
      const filterKey = `sort=${filters["sort"]}`;
      filterKeys.push(filterKey);
    }
    if (filters["sortBy"]) {
      const filterKey = `sortBy=${filters["sortBy"]}`;
      filterKeys.push(filterKey);
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cyapss/agency/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }
  public getPeersApplicationList(filters: any) {
    const filterKeys: string[] = [];
    const keys = Object.keys(filters);
    for (let i = 0; i < keys.length; i++) {
      if (!utils.emptyValues.includes(filters[keys[i]])) {
        const filterKey = `${keys[i]}=${filters[keys[i]]}`;
        filterKeys.push(filterKey);
      }
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cyapss/peers?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }
  public getPeersPreviewApplicationList(data: any) {
    const endPoint = `cyapss/peers/applications`;
    const headers = http.getTokenHeaders();
    return http.post(endPoint, headers, data).then((response) => {
      return response.data;
    });
  }
  public getPeersProfilePicDelete(data: any) {
    const endPoint = `cyapss/peers/profile-pic`;
    const headers = http.getTokenHeaders();
    return http.delete(endPoint, headers, data).then((response) => {
      return response;
    });
  }
  public getImpersonate(data: any) {
    const endPoint = `cyapss/peers/impersonate`;
    const headers = http.getTokenHeaders();
    return http.post(endPoint, headers, data).then((response) => {
      return response.data;
    });
  }
}

export const getApplicationDetails = GetApplicationDetails.instance;
